import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import logo from "../assets/logo.svg";
import SearchDrawer from "./SearchDrawer";
import NotiDrawer from "./NotiDrawer";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import LanguageSelector from "app/util/LanguageSelector";
import Header from "header/Header";
import { isCreator } from "app/core/common";
import { FaLessThanEqual } from "react-icons/fa";
import { getParam } from "app/helpers/helper";
import MobileMenu from "./MobileMenu";

export default function Sidebar({
  isLoggedIn,
  children,
  containerRef,
}: {
  isLoggedIn: boolean;
  children: any;
  containerRef: any;
}) {
  const { userInfo } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [noheader, setNoHeader] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(false);

  const origin = window.location.origin;
  const pathname = window.location.pathname;

  const userNav = [
    /* {
      name: "Wallet",
      to: `/user/${userInfo?.username ?? userInfo?.id}?tab=nfts`,
      icon: origin + "/assets/icons/wallet",
      auth: true,
      type: "page",
      tab: ["nfts"],
    }, */
    {
      name: <Trans>profile</Trans>,
      to: `/user/${userInfo?.username ?? userInfo?.id}`,
      icon: origin + "/assets/icons/profile",
      auth: true,
      type: "page",
      tab: ["collections", "posts"],
    },
    {
      name: "Wallet",
      to: `/user/${userInfo?.username ?? userInfo?.id}?tab=nfts`,
      icon: origin + "/assets/icons/wallet",
      auth: true,
      type: "page",
      tab: ["nfts"],
    },
  ];

  // Mobile Menus
  const mobileMenu = [
    {
      name: "Discover",
      to: "/",
      icon: origin + "/assets/icons/discover",
      auth: false,
      type: "page",
    },
    {
      name: "Awards",
      to: "/wma/list",
      icon: origin + "/assets/icons/wma",
      auth: false,
      type: "page",
    },
    {
      name: "Feed",
      to: "/feed",
      icon: origin + "/assets/icons/feed",
      auth: false,
      type: "page",
    },
    {
      name: "Artists",
      to: "/loops/",
      icon: origin + "/assets/icons/loops",
      auth: false,
      type: "page",
    },
    /* {
      name: <Trans>profile</Trans>,
      to: `/user/${userInfo?.username ?? userInfo?.id}`,
      icon: origin + "/assets/icons/profile",
      auth: true,
      type: "page",
      tab: ["collections", "posts"],
    }, */
    {
      name: "Menu",
      to: "#",
      icon: origin + "/assets/icons/menu_icon",
      auth: false,
      type: "mobile_menu",
    },
  ];

  // Desktop Menus
  const mainNav1 = [
    {
      name: "Discover",
      to: "/",
      icon: origin + "/assets/icons/home",
      auth: false,
      type: "page",
    },
    {
      name: "W3 Awards",
      to: "/wma/list",
      icon: origin + "/assets/icons/wma",
      auth: false,
      type: "page",
    },
    {
      name: "Browse Artists",
      to: "/loops/",
      icon: origin + "/assets/icons/loops",
      auth: false,
      type: "page",
    },
  ];

  const mainNav2 = [
    {
      name: "Music Library",
      to: "/music-library",
      icon: origin + "/assets/icons/music_library",
      auth: true,
      type: "page",
    },
    {
      name: "Feed",
      to: "/feed",
      icon: origin + "/assets/icons/feed",
      auth: true,
      type: "page",
    },

    {
      name: "Collab",
      to: "/collaboration",
      icon: origin + "/assets/icons/collaboration",
      auth: true,
      type: "page",
    },
    /* {
      name: <Trans>notifications</Trans>,
      to: "#",
      icon: origin + "/assets/icons/notification",
      auth: true,
      type: "notifications",
    }, */
  ];

  const rewardsNav = [
    {
      name: isCreator(userInfo?.role) ? "Win $FANS" : "Win $FANS",
      to: "/earn-points/",
      icon: origin + "/assets/icons/earn",
      auth: true,
      type: "page",
      creatorOnly: false,
      external: false,
    },
    /* {
      name: "Win $FANS (old)",
      to: "https://sweepwidget.com/c/80641-4xy6cbvw",
      icon: origin + "/assets/icons/earn",
      auth: true,
      type: "page",
      creatorOnly: true,
      external: true,
    }, */
    {
      name: "Promo Tools",
      to: "https://home.loop.fans/powerup",
      icon: origin + "/assets/icons/hub",
      auth: true,
      type: "page",
      creatorOnly: true,
      external: true,
    },
    /* {
      name: "Remix Contest",
      to: "/remix-contest/",
      icon: origin + "/assets/icons/earn",
      auth: false,
      type: "page",
      creatorOnly: false,
    }, */
  ];

  return (
    <>
      <Header noheader={noheader} />
      <div
        className={`${styles.sidebar} container`}
        style={notiDrawer || searchDrawer ? { overflow: "hidden" } : {}}
      >
        {!pathname.includes("/login") && (
          <div
            className={
              notiDrawer || searchDrawer
                ? `${styles.sidebarCollapse} ${styles.sidebarContent}`
                : styles.sidebarContent
            }
          >
            {/* Search */}
            <SearchDrawer show={searchDrawer} setShow={setSearchDrawer} />

            {/* Notifications */}
            <NotiDrawer show={notiDrawer} setShow={setNotiDrawer} />

            {/* Mobile Menu */}
            <MobileMenu show={mobileDrawer} setShow={setMobileDrawer} />

            <div className={userInfo ? styles.navbar : `${styles.navbar}`}>
              <ul className={styles.mobileMenu}>
                {
                  <>
                    {mobileMenu.map((item: any, index: Number) => {
                      return (
                        <li
                          className={
                            location.pathname === item.to ? styles.active : ""
                          }
                          key={`item-${index}`}
                          hidden={
                            item.creatorOnly
                              ? !isCreator(userInfo?.role)
                              : item.auth
                              ? !Boolean(userInfo)
                              : false
                          }
                        >
                          <Link
                            to={item.to}
                            onClick={() => {
                              if (item.type === "search") {
                                notiDrawer && setNotiDrawer(false);
                                mobileDrawer && setMobileDrawer(false);
                                setSearchDrawer(!searchDrawer);
                              } else if (item.type === "notifications") {
                                searchDrawer && setSearchDrawer(false);
                                mobileDrawer && setMobileDrawer(false);
                                setNotiDrawer(!notiDrawer);
                              } else if (item.type === "mobile_menu") {
                                setMobileDrawer(!mobileDrawer);
                              } else {
                                setSearchDrawer(false);
                                setNotiDrawer(false);
                                setMobileDrawer(false);
                              }
                            }}
                          >
                            <img
                              alt={item.name}
                              src={
                                location.pathname === item.to
                                  ? `${item.icon}_active.svg`
                                  : `${item.icon}.svg`
                              }
                            />
                            <span>{item.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                    <hr />
                  </>
                }
              </ul>
              <ul className={styles.desktopMenu}>
                {/* Main Navbar */}
                {mainNav1.map((item: any, index: Number) => {
                  return (
                    <li
                      className={
                        location.pathname === item.to ? styles.active : ""
                      }
                      key={`item-${index}`}
                      hidden={
                        item.creatorOnly
                          ? !isCreator(userInfo?.role)
                          : item.auth
                          ? !Boolean(userInfo)
                          : false
                      }
                    >
                      <Link
                        to={item.to}
                        onClick={() => {
                          if (item.type === "search") {
                            notiDrawer && setNotiDrawer(false);
                            setSearchDrawer(!searchDrawer);
                          } else if (item.type === "notifications") {
                            searchDrawer && setSearchDrawer(false);
                            setNotiDrawer(!notiDrawer);
                          } else {
                            setSearchDrawer(false);
                            setNotiDrawer(false);
                          }
                        }}
                      >
                        <img
                          alt={item.name}
                          src={
                            location.pathname === item.to
                              ? `${item.icon}_active.svg`
                              : `${item.icon}.svg`
                          }
                        />
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  );
                })}

                <hr />

                {mainNav2.map((item: any, index: Number) => {
                  return (
                    <li
                      className={
                        location.pathname === item.to ? styles.active : ""
                      }
                      key={`item-${index}`}
                      hidden={
                        item.creatorOnly
                          ? !isCreator(userInfo?.role)
                          : item.auth
                          ? !Boolean(userInfo)
                          : false
                      }
                    >
                      <Link
                        to={item.to}
                        onClick={() => {
                          if (item.type === "search") {
                            notiDrawer && setNotiDrawer(false);
                            setSearchDrawer(!searchDrawer);
                          } else if (item.type === "notifications") {
                            searchDrawer && setSearchDrawer(false);
                            setNotiDrawer(!notiDrawer);
                          } else {
                            setSearchDrawer(false);
                            setNotiDrawer(false);
                          }
                        }}
                      >
                        <img
                          alt={item.name}
                          src={
                            location.pathname === item.to
                              ? `${item.icon}_active.svg`
                              : `${item.icon}.svg`
                          }
                        />
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  );
                })}

                <hr />

                {/* User Navbar */}
                {/* {!!userInfo && (
                  <>
                    {userNav.map((item: any, index: Number) => {
                      return (
                        <li
                          className={
                            !!item?.tab?.includes(getParam("tab"))
                              ? styles.active
                              : ""
                          }
                          key={`item-${index}`}
                          hidden={item.auth ? !Boolean(userInfo) : false}
                        >
                          <Link
                            to={item.to}
                            onClick={() => {
                              if (item.type === "search") {
                                notiDrawer && setNotiDrawer(false);
                                setSearchDrawer(!searchDrawer);
                              } else if (item.type === "notifications") {
                                searchDrawer && setSearchDrawer(false);
                                setNotiDrawer(!notiDrawer);
                              } else {
                                setSearchDrawer(false);
                                setNotiDrawer(false);
                              }
                            }}
                          >
                            <img
                              alt={item.name}
                              src={
                                !!item?.tab?.includes(getParam("tab"))
                                  ? `${item.icon}_active.svg`
                                  : `${item.icon}.svg`
                              }
                            />
                            <span>{item.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                    <hr />
                  </>
                )} */}

                {rewardsNav.map((item: any, index: Number) => {
                  return (
                    <li
                      className={
                        location.pathname === item.to ? styles.active : ""
                      }
                      key={`item-${index}`}
                      hidden={
                        item.creatorOnly
                          ? !isCreator(userInfo?.role)
                          : item.auth
                          ? !Boolean(userInfo)
                          : false
                      }
                    >
                      {item.external ? (
                        <a href={item.to} target="_blank" rel="noreferrer">
                          <img
                            alt={item.name}
                            src={
                              location.pathname === item.to
                                ? `${item.icon}_active.svg`
                                : `${item.icon}.svg`
                            }
                          />
                          <span>{item.name}</span>
                        </a>
                      ) : (
                        <Link
                          to={item.to}
                          onClick={() => {
                            if (item.type === "search") {
                              notiDrawer && setNotiDrawer(false);
                              setSearchDrawer(!searchDrawer);
                            } else if (item.type === "notifications") {
                              searchDrawer && setSearchDrawer(false);
                              setNotiDrawer(!notiDrawer);
                            } else {
                              setSearchDrawer(false);
                              setNotiDrawer(false);
                            }
                          }}
                        >
                          <img
                            alt={item.name}
                            src={
                              location.pathname === item.to
                                ? `${item.icon}_active.svg`
                                : `${item.icon}.svg`
                            }
                          />
                          <span>{item.name}</span>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>

              <div className={styles.footer}>
                {/* <LanguageSelector /> */}
                {/* {isLoggedIn ? (
                  <Button
                    outline={false}
                    onClick={() => {
                      logout();
                      window.location.reload();
                    }}
                  >
                    <img alt="logout" src={`/assets/icons/logout.svg`} />
                    <span>Logout</span>
                  </Button>
                ) : (
                  <Button onClick={() => setShowLoginModal(true)}>Login</Button>
                )} */}
                {/* {!isLoggedIn && <Button onClick={() => setShowLoginModal(true)}>Login</Button>} */}
              </div>
            </div>
          </div>
        )}
        <div className={styles.bodyContent} id="bodyContent" ref={containerRef}>
          {children}
          {pathname === "/feed" ||
          pathname.includes("/collection/") ||
          pathname.includes("/nftDetail/") ? (
            <div className={styles.walletSidebar} id="sidebarContent">
              {/* <ins className="aso-zone" data-zone="115239"></ins> */}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
